import React from 'react';
import 'react-fastclick';
import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { IndexRoute, Redirect, Router, Route, browserHistory } from 'react-router';
import { syncHistoryWithStore, routerReducer, routerMiddleware } from 'react-router-redux';
import $ from 'jquery';
import 'hudl-react-kickoff/legacy/lib/kickoff/layout-main';
import { data, model } from 'hudl-base';
import userProfileApp from 'reducers/user-profile';
import './user-profile-page.scss';
import 'react-select/dist/react-select.css';

import 'entries/video-page-video/utility/page-load';

import UsageLogger, { UserRoles } from 'common/usage-logger';
import ProfileType from 'common/container-subtype';
import * as AboutSectionTypes from 'common/about-section-types';
import App from 'components/user-profile/app';
import UserTimelineTab from 'components/user-profile/timeline/UserTimelineTab';
import UserHighlightsTab from 'components/user-profile/highlights/UserHighlightsTab';
import UserAboutTab from 'components/user-profile/about/UserAboutTab';
import UserContactTab from 'components/user-profile/contact/UserContactTab';
import GamesTab from 'components/shared/profile-tabs/games/games-tab';
import g11n from 'hudl-g11n';
import localforage from 'common/localforage';
import { setupRateLimitedMouseFlow } from 'common/mouse-flow';
import WebNav from 'components/shared/nav';
import * as HudlFeed from 'hudl-feed';
import loadAsyncScripts from 'common/async-scripts';
import promoteDynamicContent from 'utility/comscore';

$(() => {
  window.__hudlui_Generic && window.__hudlui_Generic.init();

  const usageLogger = new UsageLogger();
  const clientModel = model.get();
  const IntlProvider = g11n.ReactIntl.IntlProvider;

  const i18n = {
    locale: data.get('i18nLocale'),
    messages: data.get('i18nMessages'),
  };

  // Prevent the browser from going to the last scroll position
  const isMobile = clientModel.isMobile;
  if (!isMobile && 'scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }

  const hasHighlightsDisabled = clientModel.hasHighlightsDisabled;

  const isRecruiter = clientModel.recruiterAccess &&
    (clientModel.recruiterAccess.hasRecruiterAccess || clientModel.recruiterAccess.canUpgradeForAccess);
  const isVideoOnlyRecruiter = isRecruiter &&
    clientModel.recruiterAccess.isVideoOnly && !clientModel.recruiterAccess.isAccessRestricted;

  const fewerThanThreeTeamsInHistory = (
    clientModel.about &&
    clientModel.about.overview &&
    clientModel.about.overview.teams &&
    clientModel.about.overview.teams.length < 3
  );
  const pageViewKey = `${clientModel.currentUser.userId}-athlete-profile-page-views`;
  const feedbackDismissedKey = `${clientModel.currentUser.userId}-athlete-profile-feedback-dismissed`;
  const feedbackCtaDismissedKey = `${clientModel.currentUser.userId}-athlete-profile-cta-feedback-dismissed`;
  localforage.hudlGetMany(
  feedbackDismissedKey,
  pageViewKey,
  feedbackCtaDismissedKey).then(values => {
    let feedbackDismissed = values[0];
    const pageViews = values[1] || 0;
    const feedbackCtaDismissed = values[2];

    let isFeedback = false;
    if (clientModel.feedback.feedbackEnabled && !feedbackCtaDismissed) {
      switch (pageViews) {
      case 49:
        localforage.setItem(feedbackDismissedKey, false);
        feedbackDismissed = false;
        isFeedback = true;
        break;
      case 29:
        localforage.setItem(feedbackDismissedKey, false);
        feedbackDismissed = false;
        isFeedback = true;
        break;
      case 9:
        localforage.setItem(feedbackDismissedKey, false);
        isFeedback = true;
        break;
      default:
        break;
      }
      if (pageViews > 49 && !feedbackDismissed) {
        isFeedback = true;
      }
      if (pageViews > 29 && !feedbackDismissed) {
        isFeedback = true;
      }
      if (pageViews > 9 && !feedbackDismissed) {
        isFeedback = true;
      }
    }

    const defaultState = {
      about: {
        activeTab: AboutSectionTypes.OVERVIEW,
        pastTeamsExpanded: fewerThanThreeTeamsInHistory,
      },
      bulkSaves: {
        isBulk: false,
      },
      contactInfo: clientModel.contactInfo,
      customize: {
        color: {
          value: clientModel.user.primaryColor || null,
          saveSuccess: false,
          saveError: false,
          error: false,
        },
        profileImage: {
          value: clientModel.user.profileLogoUri,
        },
        bannerImage: {
          value: clientModel.user.profileBannerUri,
        },
        tagline: {
          value: clientModel.user.description,
          saveSuccess: false,
          saveError: false,
          error: false,
        },
        recruitOptIn: {
          value: clientModel.isAthleteOptedInToRecruit,
        },
      },
      feedback: {
        feedbackEnabled: isFeedback,
      },
      highlightEditorIdentityData: clientModel.highlightEditorIdentityData,
      onboarding: {
        isDismissed: true,
        steps: [],
        currentStep: null,
      },
      highlights: {
        sort: clientModel.currentUser.isPrivilegedUser || isRecruiter ? 'recent' : 'views',
        hasHighlightsDisabled,
      },
      recruiterAccess: clientModel.recruiterAccess,
      games: {
        profileType: ProfileType.User,
        fullGames: null,
        loading: false,
        error: false,
        teams: clientModel.games.teams,
        selectedTeamId: clientModel.games.defaultTeamId,
        selectedSeasonId: clientModel.games.defaultTeamId ?
          clientModel.games.teams[clientModel.games.defaultTeamId].defaultSeasonId :
          null,
        isAccessRestricted: clientModel.games.isAccessRestricted,
        userEmail: clientModel.currentUser.emailAddress,
      },
      mediaSnowplowTrackerContext: clientModel.mediaSnowplowTrackerContext,
    };

    delete clientModel.feedback.feedbackEnabled;

    // The default selected season is the current one
    const { defaultState: defaultFeedState } = HudlFeed.store;

    // We use the jquery extend method in order to get a proper deep copy
    const initialState = $.extend(true, {}, defaultState, defaultFeedState, clientModel);

    const feedReducers = HudlFeed.reducers;
    const reducer = combineReducers(Object.assign({}, feedReducers, {
      routing: routerReducer,
    }, userProfileApp));

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
      reducer,
      initialState,
      composeEnhancers(applyMiddleware(routerMiddleware(browserHistory), thunkMiddleware))
    );

    // Sync dispatched route actions to the history
    const isPublic = (initialState.forcePublicView || !initialState.currentUser
                      || !initialState.currentUser.isPrivilegedUser);
    const history = syncHistoryWithStore(browserHistory, store);

    // Log the user profile view on every location (tab) change
    let role = UserRoles.Public;
    if (initialState.currentUser) {
      if (initialState.currentUser.userId === initialState.user.userId) {
        role = UserRoles.Athlete;
      } else if (initialState.currentUser.isPrivilegedUser) {
        role = UserRoles.Coach;
      } else if (initialState.recruiterAccess) {
        role = UserRoles.Recruiter;
      }
    }

    // Initial page loads can be redirected (e.g., Timeline, Videos); only count the actual page loaded
    // for the user as the initial load
    let isInitialLoad = true;

    // Don't log through google analytics on first page view since kickoff does that by default
    let logGoogleAnalytics = false;

    history.listen(location => {
      const logged = usageLogger.logUserLocation(
        location,
        initialState.user.userId,
        isPublic,
        logGoogleAnalytics,
        isInitialLoad,
        role);

      if (logged) {
        isInitialLoad = false;
      }

      logGoogleAnalytics = true;

      if(!isInitialLoad) {
        promoteDynamicContent(clientModel.comScoreId);
      }  
    });

    const rootElement = document.getElementById('app');
    const loadingStateElement = document.getElementById('feed-loading-state');
    if (loadingStateElement) {
      loadingStateElement.style.display = 'none';
    }

    const showRecruitGamesTab = clientModel.currentUser.isOnTeamInRecruit || isRecruiter;
    const showRecruitContactTab = clientModel.currentUser.isOnTeamInRecruit || (isRecruiter && !isVideoOnlyRecruiter);

    render(
      <IntlProvider {...i18n}>
        <Provider store={store}>
          <Router history={history}>
            <Route path="/" component={WebNav}>
              <Route path="/profile">
                <Route path=":userId">
                  <Route path=":seoFriendlyName" component={App}>
                    <IndexRoute component={UserTimelineTab}/>
                    <Redirect from="timeline" to="/profile/:userId/:seoFriendlyName" />
                    { !hasHighlightsDisabled && [
                      <Route path="highlights" component={UserHighlightsTab}/>,
                      <Redirect from="videos" to="/profile/:userId/:seoFriendlyName/highlights"/>,
                    ]}
                    <Route path="about" component={UserAboutTab}/>
                    { showRecruitGamesTab
                      ? <Route path="games" component={GamesTab}/>
                      : <Redirect from="games" to="/profile/:userId/:seoFriendlyName/timeline"/>
                    }
                    { showRecruitContactTab
                      ? <Route path="contact" component={UserContactTab}/>
                      : <Redirect from="contact" to="/profile/:userId/:seoFriendlyName/timeline"/>
                    }
                  </Route>
                </Route>
              </Route>
            </Route>
          </Router>
        </Provider>
      </IntlProvider>,
      rootElement,
      () => {
        if (!isMobile) {
          // Scroll part way down the header
          const scrollArea = document.querySelector('.parallax');
          if (scrollArea) {
            scrollArea.scrollTop = 270;
          }
        }
      });
    setupRateLimitedMouseFlow(data.getNullable('mouseFlowSourceUrl'), data.getNullable('mouseFlowRate') || 0,
      !data.getNullable('disableMouseFlowCookieCheck'));
  });
  loadAsyncScripts();
});
