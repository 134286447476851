import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditProfileButton from 'components/shared/buttons/edit-profile-button';
import FeedUserTypes from 'common/feed-user-type';
import FollowButton from 'components/shared/buttons/follow-button';
import MessageButton from 'components/shared/buttons/message-button';
import ShareButton from 'components/shared/buttons/share-button';
import SaveProspectButton from 'components/shared/buttons/save-prospect-button';
import { checkCanSaveProspect } from '../../utility/recruit-utility';

const ProfileHeaderButtons = (props) => {
  const buttons = [];
  const isLoggedIn = !!(props.user && props.user.userId);
  const isMobile = props.isMobile;

  if (isLoggedIn) {
    const {
      hasAnyRoleOnTeam,
      isFollowing,
      isPrivilegedUser,
      isTeammate,
      messagingEnabled,
      userId,
    } = props.user;

    // Settings Button
    if (isPrivilegedUser) {
      buttons.push(
        <EditProfileButton
          key="edit-profile-button"
          onClick={props.onEditProfileClick}
          size="small"
          type="subtle"
        />
      );
    }

    // Message Button
    const isUserProfileAndIsCurrentUser = userId === props.feedUserId && props.feedUserType === FeedUserTypes.User;
    if (isTeammate && messagingEnabled && !isUserProfileAndIsCurrentUser) {
      buttons.push(
        <MessageButton
          key="message-button"
          onClick={props.onMessagingButtonClick}
          size="small"
          type="subtle"
        />
      );
    }

    // Share Button
    const isTeamProfileAndIsCoach = isPrivilegedUser && props.feedUserType === FeedUserTypes.Team;
    if (!props.hideNav) {
      let type;
      if (isUserProfileAndIsCurrentUser || isTeamProfileAndIsCoach) {
        type = 'primary';
      } else {
        type = 'subtle';
      }

      buttons.push(
        <ShareButton
          key="share-button"
          onClick={props.onShareButtonClick}
          size="small"
          type={type}
        />
      );
    }

    // Follow Button
    const isTeamProfileAndUserOnTeam = hasAnyRoleOnTeam && props.feedUserType === FeedUserTypes.Team;
    if (!props.hideFollow && !isTeamProfileAndUserOnTeam && !isUserProfileAndIsCurrentUser) {
      buttons.push(
        <FollowButton
          key="follow-button"
          feedUserTypeId={props.feedUserType}
          feedUserRelatedId={props.feedUserId}
          hideText={isMobile && (isTeammate || isPrivilegedUser)}
          isFollowing={isFollowing}
          primary={!props.canSaveProspect}
          requesting={!!props.user.pendingTeamFollowRequest}
          size="small"
          error={props.user.followRequestError}
          onClick={props.onFollowUnfollow}
          allowUnfollow
        />
      );
    }

    // If this is a coach looking at an athlete's profile and messaging is enabled,
    // there will be too many buttons for the navbar. Here we remove the
    // 2nd one (messaging)
    if (props.isNavbar && buttons.length > 3) {
      buttons.splice(1, 1);
    }
  } else if (!props.hideNav) {
    const shareButtonType = 'primary';

    // Logged out guest
    buttons.push(
      <ShareButton
        key="share-button"
        onClick={props.onShareButtonClick}
        size="small"
        type={shareButtonType}
      />
    );
  }

  if (props.canSaveProspect) {
    buttons.push(<SaveProspectButton />);
  }

  return (
    <div
      className={
        'prof-header-buttons ' +
        (isMobile ? 'buttons-mobile' : 'buttons-desktop')
      }
    >
      {buttons}
    </div>
  );
};

ProfileHeaderButtons.propTypes = {
  canSaveProspect: PropTypes.bool.isRequired,
  feedUserId: PropTypes.string.isRequired,
  feedUserType: PropTypes.oneOf(
    Object.keys(FeedUserTypes).map((key) => FeedUserTypes[key])
  ),
  hideFollow: PropTypes.bool,
  hideNav: PropTypes.bool,
  isMobile: PropTypes.bool,
  isNavbar: PropTypes.bool,
  onEditProfileClick: PropTypes.func,
  onFollowUnfollow: PropTypes.func,
  onMessagingButtonClick: PropTypes.func,
  onShareButtonClick: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    canSaveProspect: checkCanSaveProspect(state),
  };
};

export default connect(mapStateToProps)(ProfileHeaderButtons);
