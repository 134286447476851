import React from 'react';
import ReactDOM from 'react-dom';

import './display-ad.scss';
import { data } from 'hudl-base';
import browserDetect from '../../utility/browser-detect';

const slots = {};

class DisplayAd extends React.Component {
  static propTypes = {
    id: React.PropTypes.string.isRequired,
    isMobile: React.PropTypes.bool,
    sizes: React.PropTypes.array.isRequired,
    path: React.PropTypes.string.isRequired,
    adSizeMapping: React.PropTypes.array,
    disableAdWrapper: React.PropTypes.bool,
    refreshInterval: React.PropTypes.number, // in seconds
    disableAdRefresh: React.PropTypes.bool,
    disableAdBlockCheck: React.PropTypes.bool,
    refreshCap: React.PropTypes.number,
  }

  static defaultProps = {
    refreshInterval: 30,
    disableAdRefresh: false,
    refreshCap: 5,
  }

  constructor() {
    super();

    this.adRefreshCount = 0;

    this.refreshTimer = null;
    this.timeUntilRefresh = 0;
    this.refreshPaused = false;
  }

  componentDidMount() {
    this.displayAdWhenReady();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.id !== this.props.id;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.displayAdWhenReady();
    }

    if (!prevProps.disableAdRefresh && this.props.disableAdRefresh) {
      this.stopRefreshTimer();
    }
  }

  componentWillUnmount() {
    this.stopRefreshTimer();
    this.removeRefreshListeners();
  }

  addRefreshListeners = () => {
    window.addEventListener('focus', this.resumeRefreshTimer);
    window.addEventListener('blur', this.pauseRefreshTimer);
  }

  startRefreshTimer() {
    this.timeUntilRefresh = Math.max(30, this.props.refreshInterval);
    this.refreshTimer = setInterval(() => {
      const percentageVisible = this.getPercentageVisible();
      if (percentageVisible >= 51 && !this.refreshPaused) {
        this.timeUntilRefresh--;
        if (this.timeUntilRefresh < 1) {
          this.refreshAdSlot();
          this.adRefreshCount++;
          if (this.adRefreshCount >= this.props.refreshCap) {
            this.stopRefreshTimer();
            this.removeRefreshListeners();
          } else {
            this.timeUntilRefresh = this.props.refreshInterval;
          }
        }
      }
    }, 1000);
  }

  adBlockDisabled = () => {
    const isMobile = this.props.isMobile || browserDetect.isModernIPad;
    if (this.props.disableAdBlockCheck || isMobile) {
      return true;
    }
    const node = ReactDOM.findDOMNode(this);
    const height = node.getBoundingClientRect().height;
    const width = node.getBoundingClientRect().width;
    return height > 1 && width > 1;
  }

  pauseRefreshTimer = () => {
    this.refreshPaused = true;
  }

  resumeRefreshTimer = () => {
    this.refreshPaused = false;
  }

  stopRefreshTimer = () => {
    clearInterval(this.refreshTimer);
  }

  removeRefreshListeners = () => {
    window.removeEventListener('focus', this.resumeRefreshTimer);
    window.removeEventListener('blur', this.pauseRefreshTimer);
  }

  createSlot(id) {
    const googletag = window.googletag;
    const adData = data.get('ads');
    const path = `/${adData.networkId}/${this.props.path}`;
    let slot = googletag.defineSlot(path, this.props.sizes, id)
      .setCollapseEmptyDiv(true);

    Object.keys(adData.targetingData).forEach(key => {
      slot = slot.setTargeting(key, adData.targetingData[key]);
    });

    if (this.props.adSizeMapping) {
      let mapping = googletag.sizeMapping();
      this.props.adSizeMapping.forEach(value => {
        mapping = mapping.addSize(value.browser, value.ad);
      });
      slot = slot.defineSizeMapping(mapping.build());
    }

    slot = slot.addService(googletag.pubads());
    slots[id] = slot;
    return slot;
  }

  addOrGetSlot(id) {
    return slots[id] || this.createSlot(id);
  }

  getPercentageVisible = () => {
    const node = ReactDOM.findDOMNode(this);
    const elemTop = node.getBoundingClientRect().top;
    const elemHeight = node.getBoundingClientRect().height;
    const elemBottom = node.getBoundingClientRect().bottom;
    const windowHeight = window.innerHeight;

    let visibility = 0;
    if (elemBottom > windowHeight) {
      // ad is off bottom of screen
      if (elemTop < windowHeight) {
        visibility = (windowHeight - elemTop) / elemHeight;
      }
    } else if (elemTop < 0) {
      // ad is off top of screen
      if (elemBottom > 0) {
        visibility = elemBottom / elemHeight;
      }
    } else {
      // ad fully visible
      visibility = 1;
    }

    const percentage = visibility * 100;
    return percentage.toFixed(0);
  };

  displayAdWhenReady() {
    const googletag = window.googletag;
    const { id, disableAdRefresh } = this.props;

    googletag.cmd.push(() => {
      const slot = this.addOrGetSlot(id);

      googletag.display(id);
      googletag.pubads().refresh([slot]);
      if(!disableAdRefresh) {
          this.addRefreshListeners();
          this.startRefreshTimer();
      }
    });
  }

  refreshAdSlot = () => {
    const googletag = window.googletag;
    const slot = this.addOrGetSlot(this.props.id);

    googletag.pubads().refresh([slot]);
  }

  render() {
    let result;
    const isMobile = this.props.isMobile || browserDetect.isModernIPad;

    if (this.props.disableAdWrapper) {
      result = (<div key={this.props.id} id={this.props.id}></div>);
    } else {
      const style = {};
      if (!isMobile) {
        style.height = 250;
        style.width = 300;
      }

      const classes = isMobile ? 'mobile-ad-container' : '';
      result = (
        <div className={classes}>
          <div key={this.props.id} id={this.props.id} style={style}>
          </div>
        </div>
      );
    }
    return result;
  }
}

export default DisplayAd;
