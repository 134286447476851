import React from 'react';
import { Button } from 'components/shared/uniform';

import './message-button.scss';

class MessageButton extends React.Component {
  static propTypes = {
    onClick: React.PropTypes.func.isRequired,
    minimal: React.PropTypes.bool,
    size: React.PropTypes.string,
    type: React.PropTypes.string,
  }

  render() {
    return (
      <span>
        <Button
          icon="messages"
          onClick={this.props.onClick}
          size={this.props.size || 'medium'}
          style={this.props.minimal ? 'minimal' : null}
          text={this.props.minimal ? undefined : 'Message'}
          type={this.props.type || 'secondary'}
        />
      </span>
    );
  }
}
export default MessageButton;
